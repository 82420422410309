export const checkTelMask = (value: string): string => {
  return value.substring(4, 5) === "9" ? "(99)99999-9999" : "(99)9999-9999"
}

export const formatCurrency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format;

export const formatDateBR = (date: string) => {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000).toLocaleDateString('pt-BR')
}

export const initialSearchDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}