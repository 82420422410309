import React, { useState, useEffect } from 'react'
import * as S from './styles'
import { BarChart } from '@mui/x-charts/BarChart';
import api from '../../utils/api'
import IStudent from '../../interfaces/IStudent';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const [chartLessons, setChartLessons] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [chartCanceledLessons, setChartCanceledLessons] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [chartPayments, setChartPayments] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [chartRegisteredStudents, setChartRegisteredStudents] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [studentsToCharge, setStudentsToCharge] = useState<IStudent[]>([])
  const [studentsBirthday, setStudentsBirthday] = useState<IStudent[]>([])
  const [teachersBirthday, setTeachersBirthday] = useState<IStudent[]>([])
  const [token] = useState(localStorage.getItem('token') || '')
  const currentYear = new Date().getFullYear();
  const today = new Date().getDate();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    api.get(`/charts/lessons`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setChartLessons(res.data.info)
    })

    api.get(`/charts/canceled-lessons`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setChartCanceledLessons(res.data.info)
    })

    api.get(`/charts/registered-students`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setChartRegisteredStudents(res.data.info)
    })

    api.get(`/charts/payments`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setChartPayments(res.data.info)
    })

    api.get(`/students/charge`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setStudentsToCharge(res.data.students)
    })

    api.get(`/students/birthday`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setStudentsBirthday(res.data.students)
    })

    api.get(`/teachers/birthday`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setTeachersBirthday(res.data.teachers)
    })
  }, [token, navigate])

  return (
    <S.Section>
      <S.Header>
        <S.HeaderTitle>Informações</S.HeaderTitle>
        <S.Paragraph>Veja as principais informações do seu negócio!</S.Paragraph>
      </S.Header>

      <S.Container>
        <S.ChartsContainer>
          <S.Item>
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'] }]}
              series={[{ data: chartLessons, label: `Total de Aulas Agendadas por Mês (${currentYear})`, color: "#34CFCA" }]}
              width={500}
              height={300}
            />
          </S.Item>
          <S.Item>
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'] }]}
              series={[{ data: chartCanceledLessons, label: `Total de Aulas Canceladas por Mês (${currentYear})`, color: "#ff4c4c" }]}
              width={500}
              height={300}
            />
          </S.Item>
          <S.Item>
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'] }]}
              series={[{ data: chartRegisteredStudents, label: `Total de Alunos Cadastros por Mês (${currentYear})`, color: "#00af5a" }]}
              width={500}
              height={300}
            />
          </S.Item>
          <S.Item>
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'] }]}
              series={[{ data: chartPayments, label: `Total Pagamentos/Recebimentos por Mês (${currentYear})`, color: "#0097FE" }]}
              width={500}
              height={300}
            />
          </S.Item>
        </S.ChartsContainer>

        <S.RemiderContainer>
          <S.HeaderTitle>Lembretes:</S.HeaderTitle>
          {studentsToCharge && studentsToCharge.map((student, index) => (
            <S.Remider key={index} color={"red"}>
              <span>
                A mensalidade do(a) aluno(a)<strong>{student.name}</strong>vence
                {today === student.paymentDay ?
                  " hoje" : today + 1 === student.paymentDay ?
                  " amanhã" : ` dia ${student.paymentDay}`}!
              </span>
            </S.Remider>
          ))}

          {studentsBirthday && studentsBirthday.map((student, index) => (
            <S.Remider key={index} color={"blue"}>
              <CelebrationIcon/>
              <span>
                Hoje é aniversário do(a) aluno(a)<strong>{student.name}</strong>
              </span>
            </S.Remider>
          ))}

          {teachersBirthday && teachersBirthday.map((teacher, index) => (
            <S.Remider key={index} color={"blue"}>
              <CelebrationIcon/>
              <span>
                Hoje é aniversário do(a) professor(a)<strong>{teacher.name}</strong>
              </span>
            </S.Remider>
          ))}

          {studentsToCharge.length === 0 && studentsBirthday.length === 0 && teachersBirthday.length === 0 &&
            <span>Não há lembretes!</span>
          }
        </S.RemiderContainer>
      </S.Container>

    </S.Section>
  )
}