import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import * as S from '../../styles/styles-forms'
import * as S2 from '../../styles/styles-inputs'
import { Input } from '../Inputs/text'
import { InputDate } from '../Inputs/date'
import { Select } from '../Select'
import ILesson from '../../interfaces/ILesson'
import SaveIcon from '@mui/icons-material/Save';
import api from '../../utils/api'
import ITeacher from '../../interfaces/ITeacher'
import IStudent from '../../interfaces/IStudent'
import { MultiSelectChip } from '../../components/MultiSelectChip'
import { SelectChangeEvent } from '@mui/material/Select';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';

interface FormLessonProps {
  handleSubmit: (event: any) => void;
  lessonData: ILesson;
  buttonText: string
}

export const FormLesson = ({
  handleSubmit,
  lessonData,
  buttonText
}: FormLessonProps) => {
  const [lesson, setLesson] = useState(lessonData || {})
  const [teachers, setTeachers] = useState<ITeacher[]>([])
  const [students, setStudents] = useState<IStudent[]>([])
  const [selectedStudents, setSelectedStudents] = useState<any[]>([])
  const [token] = useState(localStorage.getItem('token') || '')

  useEffect(() => {
    if(lesson.students.length)
      setSelectedStudents(lesson.students.map(stud => stud._id))

    api.get(`/teachers`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setTeachers(res.data.teachers)
    }).catch((err: any) => {
      console.log(err)
    })

    api.get(`/students/lesson`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setStudents(res.data.students)
    }).catch((err: any) => {
      console.log(err)
    })
    // eslint-disable-next-line
  }, [token])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLesson({ ...lesson, [name]: value });
  }

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const name = e.target.name;
    const value = e.target.options[e.target.selectedIndex].value as string;
    setLesson({ ...lesson, [name]: value });
  }

  const handleMultiSelect = (e: SelectChangeEvent<any>) => {
    const { value } = e.target
    const removedId = selectedStudents.find(id => !value.includes(id));

    if(removedId && lesson.students.find(stud => stud._id === removedId).status !== "SCHEDULED")
      return

    setSelectedStudents(value);

    const _students:any = students

    const studentsWithStatus = value.map((item: any) => {
      const stud = lesson.students.find((stud) => stud._id === item )
      return {
        _id: item,
        status: stud ? stud.status : "SCHEDULED",
        isOverdued: _students.find((stud:any) => stud._id === item)?.isOverdued,
        achievedMaxLessons: _students.find((stud:any) => stud._id === item)?.achievedMaxLessons
      }
    });

    setLesson(prevLesson => ({
      ...prevLesson,
      students: studentsWithStatus
    }));
  }

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(lesson)
  }

  const changeStatus = (e: any, id: any, status: string) => {
    e.preventDefault()

    const students = lesson.students.map((student) => {
      if(student._id === id)
        student.status = status

      return student
    })

    setLesson({ ...lesson, students: students });
  }

  return (
    <S.FormContainer onSubmit={submit}>
      <InputDate
        text="Data da Aula"
        name="date"
        handleOnChange={handleChange}
        value={lesson.date ? new Date(lesson.date).toISOString().split('T')[0] : ""}
        required={true}
        todayIsMin
      />
      <S2.Row>
        <Input
          type="time"
          text="Inicio"
          name="hour_start"
          handleOnChange={handleChange}
          value={lesson.hour_start}
          required={true}
          tooltipText="Por favor, insira o horário no formato de 24 horas. Por exemplo, utilize '15:00' para representar 3 da tarde."
        />
        <Input
          type="time"
          text="Fim"
          name="hour_end"
          handleOnChange={handleChange}
          value={lesson.hour_end}
          required={true}
          tooltipText="Por favor, insira o horário no formato de 24 horas. Por exemplo, utilize '15:00' para representar 3 da tarde."
        />
      </S2.Row>
      <Select
        text="Professor"
        name="teacher"
        options={teachers}
        handleOnChange={handleSelect}
        value={lesson.teacher}
        required={true}
      />
      <MultiSelectChip
        text="Alunos"
        name="students"
        options={students}
        handleOnChange={handleMultiSelect}
        value={selectedStudents}
        required={true}
        tooltipText={<>Se o aluno tiver feito o máximo de aulas no mês corrente, ele aparecerá na lista com o ícone <CastForEducationIcon fontSize='small' color='error'/>.
        <br/><br/>
        Se o aluno estiver com a mensalidade vencida, ele aparecerá na lista com o ícone <AttachMoneyIcon fontSize='small' color='error'/>.
        <br/><br/>
        Após a aula ter ocorrido, volte e confirme a lista de presença!
        <br/><br/>
        Após confirmado presença ou falta não será possível retirar o aluno da aula!</>}
      />
      {lesson.students.length > 0 &&
        <S.StudentSection>
          {lesson.students.map((student) => (
            <S.StudentRow key={student._id}>
              <S.StudentSpan color={
                student.status === "MISSED" ? "red" :
                student.status === "SCHEDULED" ? "gray" :
                "green"
              }>
                {students.find((stud: any) => stud._id === student._id)?.name}
              </S.StudentSpan>
              <S.StatusSection>
                {student.isOverdued &&
                  <AttachMoneyIcon color='error'></AttachMoneyIcon>
                }

                {student.achievedMaxLessons &&
                  <CastForEducationIcon color='error'></CastForEducationIcon>
                }

                {student.status === "SCHEDULED" &&
                  <S.NegativeStudentButton onClick={(e) => changeStatus(e, student._id, "MISSED")}>
                    Faltou
                  </S.NegativeStudentButton>
                }

                {student.status === "SCHEDULED" &&
                  <S.PositiveStudentButton onClick={(e) => changeStatus(e, student._id, "DONE")}>
                    Presente
                  </S.PositiveStudentButton>
                }
              </S.StatusSection>
            </S.StudentRow>
          ))}
        </S.StudentSection>
      }
      <Input
        text="Classe"
        type="text"
        name="classroom"
        placeholder="Digite a classe"
        handleOnChange={handleChange}
        value={lesson.classroom || ""}
      />
      <Input
        text="Assunto"
        type="text"
        name="subject"
        placeholder="Digite o assunto"
        handleOnChange={handleChange}
        value={lesson.subject || ""}
      />
      <Input
        text="Observação"
        type="text"
        name="observation"
        placeholder="Digite alguma observação"
        handleOnChange={handleChange}
        value={lesson.observation || ""}
      />
      <S.SubmitButton>
        {buttonText}&nbsp;
        <SaveIcon />
      </S.SubmitButton>
    </S.FormContainer>
  )
}