import axios from 'axios'

const baseURL = process.env.NODE_ENV === 'production'
? process.env.REACT_APP_API_PRD
: process.env.REACT_APP_API_DEV;

console.log("🚀 ~ NODE_ENV:", process.env.NODE_ENV)
console.log("🚀 ~ baseURL:", baseURL)

export default axios.create({
    baseURL: baseURL
})