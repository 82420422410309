import styled, { css, CSSObject } from "styled-components";
import "../../index.css"
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.png'

const breakpoints = {
  small: "576px",
  medium: "768px",
  large: "992px",
  extraLarge: "1200px",
} as const;

type Breakpoints = keyof typeof breakpoints;

const media = (Object.keys(breakpoints) as Breakpoints[]).reduce(
  (acc, label) => {
    acc[label] = (styles: CSSObject) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(styles)}
      }
    `;

    return acc;
  },
  {} as Record<Breakpoints, (styles: CSSObject) => ReturnType<typeof css>>
);

export const Navbar = styled.nav`
	display: flex;
  flex-direction: column;
	height: 100vh;
  overflow: scroll;
  max-width: 240px;
  padding: 5px;
  position: fixed;
  background-color: var(--white);

  ${media.medium({
    width: "65px"
  })}
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

export const NavbarLogo = styled(Link)`
	display: flex;
	align-items: center;
  margin-top: 0.5rem;
  justify-content: center;
`

export const ImgLogo = styled.img`
	width: 80%;
  margin-bottom: 2rem;

  ${media.medium({
    content: `url(${Logo})`,
  })}
`

export const List = styled.ul`
	display: flex;
	align-items: flex-start;
	list-style: none;
  flex-direction: column;
	height: 100vh;
`

export const ListItem = styled(Link) <{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  width: 100%;

  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  border-bottom: 1px solid lightgray;
  text-decoration: none;

  color: ${({ color }) => color ? `var(--${color})` : 'var(--blue)'};

  &:hover {
    background-color: ${({ color }) => color ? `var(--${color})` : 'var(--blue)'};
    color: var(--white);

    a {
      color: var(--white);
    }
  }

  a {
    color: ${({ color }) => color ? `var(--${color})` : 'var(--blue)'};
    text-decoration: none;
    display: flex;
    padding: 0.4rem 0.6rem;
    font-size: 14px;
  }

  span{
    margin-left: 0.5rem;
    ${media.medium({
      display: "none"
    })}
  }
`;

export const ListItemSuper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  width: 100%;

  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  border-bottom: 1px solid var(--lightgray);
  text-decoration: none;

  color: var(--white);
  background-color: var(--blue);

  &:hover {
    background-color: var(--white);
    color: var(--blue);

    a {
      color: var(--blue);
    }
  }

  a {
    color: var(--blue)
    text-decoration: none;
    display: flex;
    padding: 0.4rem 0.6rem;
    font-size: 14px;
  }

  span{
    margin-left: 0.5rem;
    ${media.medium({
      display: "none"
    })}
  }
`;
