import React, { useState, useEffect } from 'react'
import * as S from "../../styles/styles-pages"
import api from '../../utils/api'
import { useParams } from 'react-router-dom'
import IPlan from '../../interfaces/IPlan'

export const InfoPlan = () => {
  const [plan, setPlan] = useState<IPlan | undefined>()
  const [infos, setInfos] = useState<any>({})
  const [token] = useState(localStorage.getItem('token') || '')
  const { id } = useParams()

  useEffect(() => {
    api.get(`/plans/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setPlan(res.data.plan)
    })

    api.get(`/plans/infos/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setInfos(res.data.infos)
    })
  }, [token, id])

  return (
    <S.Section>
      <div>
        <S.Header>Informações sobre o Plano: <strong>{plan?.name}</strong></S.Header>
        <p>Confirma algumas informações úteis sobre o plano.</p>
      </div>

      <S.InfosContainer>
        <S.InfosRow>Quantidade de alunos nesse plano: <strong>{infos.totalStudents || 0}</strong></S.InfosRow>
      </S.InfosContainer>
    </S.Section>
  )
}