import React, { useState, useEffect, ChangeEvent } from 'react'
import useFlashMessage from '../../hooks/useFlashMessage'
import api from '../../utils/api'
import * as S from '../../styles/styles-lists'
import IPlan from '../../interfaces/IPlan'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { SearchInput } from '../../components/SearchInput'
import InfoIcon from '@mui/icons-material/Info';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import { formatCurrency } from '../../utils/utils'

export const ListPlans = () => {
  const [plans, setPlans] = useState<IPlan[] | undefined>()
  const [token] = useState(localStorage.getItem('token') || '')
  const { setFlashMessage } = useFlashMessage()

  useEffect(() => {
    api.get('/plans', {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setPlans(res.data.plans)
    }).catch((err) => {
      console.log("🚀 ~ err:", err.message)
      setFlashMessage("Houve um problema, tente novamente mais tarde!", "error")
    })
  }, [token, setFlashMessage])

  const removePlan = async (id: string) => {
    let msgType = "success"

    const data = await api.delete(`/plans/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      const updatedPlans = plans?.filter((plan: IPlan) => plan._id !== id)
      setPlans(updatedPlans)

      return res.data
    }).catch((err) => {
      msgType = "error"
      return err.response.data
    })

    setFlashMessage(data.message, msgType)
  }

  const handleFilter = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    api.get('/plans', {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      if (value.trim() === '') {
        setPlans(res.data.plans)
      } else {
        const filteredPlans = res.data.plans?.filter((plan: IPlan) => plan.name.toLowerCase().includes(value.toLowerCase()));
        setPlans(filteredPlans);
      }
    })
  }

  return (
    <section>
      <S.ListHeader>
        <S.ListHeaderTitle>
          Planos&nbsp;({plans?.length})&nbsp;
          <AnalyticsIcon fontSize='small' />
        </S.ListHeaderTitle>

        <SearchInput
          name='search'
          placeholder='Buscar por nome'
          handleOnChange={handleFilter}
        />

        <S.ListHeaderLink to='/plan/add'>
          <span>Cadastrar Plano</span>
          <AddIcon fontSize='small' />
        </S.ListHeaderLink>
      </S.ListHeader>

      <S.ListContainer>
        {plans && plans.length > 0 && plans.map((plan) => (
          <S.ListRow key={plan._id}>
            <S.Data>
              <S.DataInfo>
                <div>
                  <DescriptionIcon fontSize={'small'} />
                  <span>{plan.name}</span>
                </div>
              </S.DataInfo>
              <S.DataDate>
                <CalendarTodayIcon fontSize={'small'} />
                <span>{plan.quantity} aulas por mês</span>
              </S.DataDate>
              <S.DataDate style={{ color: 'green' }}>
                <span>{formatCurrency(plan.value)}</span>
              </S.DataDate>
            </S.Data>

            <S.Actions>
              <S.ActionsLink to={`/plan/edit/${plan._id}`}>
                <span>Editar</span>
                <EditIcon fontSize={'small'} />
              </S.ActionsLink>
              <S.ActionsButton color={"red"} onClick={() => removePlan(plan._id || "")}>
                <span>Excluir</span>
                <DeleteIcon fontSize={'small'} />
              </S.ActionsButton>
              <S.ActionsLink to={`/plan/info/${plan._id}`}>
                <span>Informações</span>
                <InfoIcon fontSize={'small'} />
              </S.ActionsLink>
            </S.Actions>
          </S.ListRow>
        ))}
        {plans?.length === 0 && (<p>Não há planos cadastrados!</p>)}
      </S.ListContainer>
    </section>
  )
}