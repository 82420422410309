import React, { useState } from "react"
import * as S from "../../styles/styles-pages"
import api from "../../utils/api"
import { useNavigate } from "react-router-dom"
import useFlashMessage from "../../hooks/useFlashMessage"
import { FormConversation } from "../../components/Forms/conversation"
import IConversation from "../../interfaces/IConversation"
import IMessage from "../../interfaces/IMessage"

export const AddConversation = () => {
  const [token] = useState(localStorage.getItem('token') || '')
  const { setFlashMessage } = useFlashMessage()
  const navigate = useNavigate()
  const voidConversation: IConversation = {
    _id: '',
    subject: '',
    active: true,
    createdAt: ''
  }
  const voidMessage: IMessage = {
    _id: '',
    text: '',
    conversation: '',
    createdAt: ''
  }

  const registerConversation = async (conversation: any, message: any) => {
    let msgType = "success"

    const formData = new FormData()

    await Object.keys(conversation).forEach((key) => {
      formData.append(key, conversation[key])
    })

    await Object.keys(message).forEach((key) => {
      formData.append(key, message[key])
    })

    const data = await api.post('/conversations/create', formData, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data
    }).catch((err) => {
      msgType = 'error'
      return err.response.data
    })

    setFlashMessage(data.message, msgType)

    if (msgType !== 'error')
      navigate('/conversations')
  }

  return (
    <S.Section>
      <div>
        <S.Header>Contato</S.Header>
        <p>Após iniciado o contato responderemos o quanto antes!</p>
      </div>
      <FormConversation
        buttonText="Iniciar"
        handleSubmit={registerConversation}
        messageData={voidMessage}
        conversationData={voidConversation}
      />
    </S.Section>
  )
}