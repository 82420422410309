import styled from "styled-components";
import "../../index.css"

export const Section = styled.section`
`

export const Header = styled.div`
  margin-bottom: 2rem;
`

export const HeaderTitle = styled.h1`
  margin-bottom: 0.3rem;
  color: var(--blue);
`

export const Paragraph = styled.p`
  margin-bottom: 1rem;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
`

export const ChartsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 60%;
`

export const RemiderContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  border-radius: 5px;
  border: 5px solid var(--white);
  padding: 2rem;
  margin: 10px;
`

export const Remider = styled.div<{ color?: string }>`
  display: flex;
  padding: 5px;
  border: 1px solid ${({ color }) => `var(--${color})`};
  color: ${({ color }) => `var(--${color})`};;
  border-radius: 5px;
  height: auto;
  background-color: var(--white);
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5px;

  span{
    padding-left: 5px;
  }

  strong {
    padding: 0 5px;
  }
`

export const Item = styled.div`
  width: 500px;
  height: 300px;
  border: 5px solid var(--white);
  border-radius: 5px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
