import styled, { css, CSSObject } from "styled-components";
import "../../index.css"

const breakpoints = {
  small: "576px",
  medium: "768px",
  large: "992px",
  extraLarge: "1200px",
} as const;

type Breakpoints = keyof typeof breakpoints;

const media = (Object.keys(breakpoints) as Breakpoints[]).reduce(
  (acc, label) => {
    acc[label] = (styles: CSSObject) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(styles)}
      }
    `;

    return acc;
  },
  {} as Record<Breakpoints, (styles: CSSObject) => ReturnType<typeof css>>
);

export const Container = styled.main<{ authenticated?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 3rem 6rem 1rem;
  margin: 0 auto;
  background-color: var(--lightgray);
  overflow: scroll;
  scroll-behavior: smooth;
  width: 100%;
  margin-left: ${({ authenticated }) => authenticated === "true" ? '240px' : '0px'};

  ${media.medium({
  padding: "1rem"
})}
`
