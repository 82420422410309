import React, { useState, useEffect, ChangeEvent, useCallback } from 'react'
import useFlashMessage from '../../hooks/useFlashMessage'
import api from '../../utils/api'
import * as S from '../../styles/styles-lists'
import ILesson from '../../interfaces/ILesson'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AlarmIcon from '@mui/icons-material/Alarm';
import { SearchInput } from '../../components/SearchInput'
import { InputDate } from '../../components/Inputs/date'
import { formatDateBR, initialSearchDate } from '../../utils/utils';

export const ListLessons = () => {
  const [lessons, setLessons] = useState<any>([])
  const [date, setDate] = useState(initialSearchDate())
  const [token] = useState(localStorage.getItem('token') || '')
  const { setFlashMessage } = useFlashMessage()

  const getAll = useCallback(() => {
    if (!date)
      return

    api.get(`/lessons/all/${date}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res: any) => {
      setLessons(checkDate(res.data.lessons))
    }).catch((err) => {
      console.log("🚀 ~ err:", err.message)
      setFlashMessage("Houve um problema, tente novamente mais tarde!", "error")
    })
  },[token, date, setFlashMessage])

  useEffect(() => {
    getAll()
  }, [getAll])

  const checkDate = (lessons: any) => {
    const currentDate = new Date();
    const currentTime = currentDate.toLocaleTimeString('en-US', { hour12: false });
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const offsetInMinutes = currentDate.getTimezoneOffset();

    const [currentHour, currentMinute] = currentTime.split(':').map(Number);
    const currentTimeInMinutes = currentHour * 60 + currentMinute;

    lessons.map((lesson: any) => {
      const [endHour, endMinute] = lesson.hour_end.split(':').map(Number);
      const endTimeInMinutes = endHour * 60 + endMinute;

      const lessonDate = new Date(new Date(lesson.date).getTime() + (offsetInMinutes * 60000));
      const lessonYear = lessonDate.getFullYear();
      const lessonMonth = lessonDate.getMonth();
      const lessonDay = lessonDate.getDate();

      if (lessonYear === currentYear && lessonMonth === currentMonth && lessonDay === currentDay) {
        if (endTimeInMinutes < currentTimeInMinutes)
          lesson.past = true;
      } else if (lessonYear < currentYear ||
        (lessonYear === currentYear && lessonMonth < currentMonth) ||
        (lessonYear === currentYear && lessonMonth === currentMonth && lessonDay < currentDay)) {
        lesson.past = true;
      }

      return lesson
    })

    return lessons
  }

  const removeLesson = async (id: string) => {
    let msgType = "success"

    const data = await api.delete(`/lessons/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      getAll()
      return res.data
    }).catch((err) => {
      msgType = "error"
      return err.response.data
    })

    setFlashMessage(data.message, msgType)
  }

  const handleFilter = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    api.get(`/lessons/all/${date}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      if (value.trim() === '') {
        setLessons(checkDate(res.data.lessons))
      } else {
        const filteredLessons = res.data.lessons?.filter((lesson: ILesson) => {
          if (typeof lesson.teacher !== 'string') {
            return lesson.teacher.name.toLowerCase().includes(value.toLowerCase())
          }
          return false
        });
        setLessons(checkDate(filteredLessons));
      }
    })
  }

  return (
    <section>
      <S.ListHeader>
        <S.ListHeaderTitle>
          Aulas&nbsp;({lessons?.length})&nbsp;
          <CastForEducationIcon fontSize='small' />
        </S.ListHeaderTitle>

        <SearchInput
          name='search'
          placeholder='Buscar por professor'
          handleOnChange={handleFilter}
        />

        <InputDate
          text='Data'
          name='date'
          value={date}
          handleOnChange={(e) => setDate(e.target.value)}
          row={"true"}
        />

        <S.ListHeaderLink to='/lesson/add'>
          <span>Cadastrar Aula</span>
          <AddIcon fontSize='small' />
        </S.ListHeaderLink>
      </S.ListHeader>

      <S.ListContainer>
        {lessons && lessons.length > 0 && lessons.map((lesson: any) => (
          <S.ListRow key={lesson._id} blocked={(lesson.past) ? "true" : "false"}>
            <S.Data deleted={(lesson.deletedAt) ? "true" : "false"}>
              <S.DataInfo>
                <div>
                  <SchoolIcon fontSize={'small'} />
                  <span>{typeof lesson.teacher === 'object' ? lesson.teacher.name : ""}</span>
                </div>
                <div>
                  <GroupsIcon fontSize={'small'} />
                  <span>{lesson.students.length} aluno(s)</span>
                </div>
              </S.DataInfo>
              <S.DataDate>
                <CalendarTodayIcon fontSize={'small'} />
                <span>
                  {formatDateBR(lesson.date)}
                </span>
              </S.DataDate>
              <S.DataDate>
                <AlarmIcon fontSize={'small'} />
                <span>{lesson.hour_start} - {lesson.hour_end}</span>
              </S.DataDate>
            </S.Data>
            {/* {(!lesson.deletedAt && !lesson.past) && // uncomment this list to set the past rule */}
            {(!lesson.deletedAt) &&
              <S.Actions>
                <S.ActionsLink to={`/lesson/edit/${lesson._id}`}>
                  <span>Editar</span>
                  <EditIcon fontSize={'small'} />
                </S.ActionsLink>
                <S.ActionsButton color={"red"} onClick={() => removeLesson(lesson._id || "")}>
                  <span>Cancelar</span>
                  <DeleteIcon fontSize={'small'} />
                </S.ActionsButton>
              </S.Actions>
            }
          </S.ListRow>
        ))}
        {lessons?.length === 0 && (<p>Não há aulas agendadas!</p>)}
      </S.ListContainer>
    </section>
  )
}