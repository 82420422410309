import React, { ChangeEvent, FormEvent, useState, useCallback } from 'react'
import * as S from '../../styles/styles-forms'
import { Input } from '../Inputs/text'
import { TextArea } from '../TextArea'
import IConversation from '../../interfaces/IConversation'
import IMessage from '../../interfaces/IMessage'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

interface FormConversationProps {
  handleSubmit: (conversation: any, message: any) => void;
  conversationData: IConversation;
  messageData: IMessage;
  buttonText: string
}

export const FormConversation = ({
  handleSubmit,
  conversationData,
  messageData,
  buttonText
}: FormConversationProps) => {
  const [conversation, setConversation] = useState(conversationData || {})
  const [message, setMessage] = useState(messageData || {})

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setConversation(prevConversation => ({ ...prevConversation, [name]: value }));
  }, [])

  const handleTextArea = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setMessage(prevMessage => ({ ...prevMessage, [name]: value }));
  }, [])

  const submit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(conversation, message)
  }, [handleSubmit, conversation, message])

  return (
    <S.FormContainer onSubmit={submit}>
      <Input
        text="Assunto"
        type="text"
        name="subject"
        placeholder="Digite o assunto do contato"
        handleOnChange={handleChange}
        value={conversation.subject || ""}
        required={true}
      />
      <TextArea
        text="Mensagem"
        name="text"
        handleOnChange={handleTextArea}
        value={message.text || ""}
        required={true}
        placeholder="Digite aqui sua mensagem"
      />
      <S.SubmitButton>
        {buttonText}&nbsp;
        <QuestionAnswerIcon/>
      </S.SubmitButton>
    </S.FormContainer>
  )
}