import styled from "styled-components";
import "../index.css"

export const FormContainer = styled.form`
  max-width: 600px;
  margin: 2rem auto;
  background-color: var(--lightgray);
  border-radius: 5px;
  border: 5px solid var(--white);
  padding: 2rem;
`

export const ChatContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  margin: 2rem auto 0px;
  background-color: var(--lightgray);
  border-radius: 5px;
  border: 5px solid var(--white);
  padding: 1rem;
  height: 75vh;
`

export const StudentSection = styled.div`
  border: 3px solid var(--light-blue);
  border-radius: 5px;
  margin: 0.6rem;

  > div:nth-child(odd) {
    background-color: var(--white);
  }

`

export const StudentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 2rem;
  padding: 0.2rem;
`

export const StudentSpan = styled.span<{ color?: string }>`
  font-size: 14px;
  color: ${({ color }) => `var(--${color})`};
`


export const ChatSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px;
`

export const MessageDate = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 9px;
  color: var(--gray);
  padding-left: 5px;
`

export const ChatPill = styled.div<{ is_super?: string }>`
  display: flex;
  flex-direction: column;
  text-align: ${({ is_super }) => is_super === "true" ? 'start' : 'end'};
  align-self: ${({ is_super }) => is_super === "true" ? 'flex-start' : 'flex-end'};
  color: ${({ is_super }) => is_super === "true" ? 'var(--light-blue)' : 'var(--blue)'};
  border-color: ${({ is_super }) => is_super === "true" ? 'var(--light-blue)' : 'var(--blue)'};
  border: 1px solid;
  background-color: var(--white);
  margin: 5px;
  border-radius: 5px;
  max-width: 400px;
  padding: 5px;
  font-size: 14px;

  strong{
    padding-right: 5px;
  }
`

export const SubmitButton = styled.button`
  border-radius: 5px;
  background-color: var(--light-blue);
  color: var(--white);
  min-width: 100px;
  padding: 0.6rem;
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;

  &:hover{
    background-color: var(--light-blue-2);
  }
`

export const NegativeStudentButton = styled.button`
  border-radius: 5px;
  background-color: var(--red);
  color: var(--white);
  cursor: pointer;
  transition: filter 0.2s;
  border: none;
  min-width: 4rem;
  margin: 0.1rem;
  padding: 0.1rem;

  &:hover{
    filter: brightness(75%);
  }
`

export const StatusSection = styled.div`
  display: flex;
  flex-direction: row;
  align-center: center;
  justify-content: center;
`

export const PositiveStudentButton = styled.button`
  border-radius: 5px;
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
  transition: filter 0.2s;
  border: none;
  min-width: 4rem;
  margin: 0.1rem;
  padding: 0.1rem;

  &:hover{
    filter: brightness(75%);
  }
`

export const SendMessageButton = styled.button`
  border-radius: 5px;
  background-color: var(--light-blue);
  color: var(--white);
  min-width: 100px;
  padding: 0.6rem;
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1.1rem;
  font-weight: bold;

  &:hover{
    background-color: var(--light-blue-2);
  }
`
