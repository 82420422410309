import styled from "styled-components";
import "../index.css"
import InfoIcon from '@mui/icons-material/Info';
import InputMask from 'react-input-mask';
import { default as SelectMUI } from '@mui/material/Select';

export const FormControl = styled.div<{ row?: string }>`
  display: flex;
  flex-direction: ${({ row }) => (row === "true" ? 'row' : 'column')};
  margin-bottom: ${({ row }) => (row === "true" ? '0' : '1rem;')};
  align-items: ${({ row }) => (row === "true" ? 'center' : 'flex-start')};
`

export const Label = styled.label`
  font-size: 0.8rem;
`

export const Input = styled.input`
  padding: 0.7rem;
  border: 0;
  border-radius: 5px;
  width: 100%;

  ::placeholder {
    color: var(--gray)
  }

  &:disabled {
    border: 1px solid var(--lightgray);
  }
`

export const InputCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid var(--light-blue);
  border-radius: 4px;
  outline: none;
  margin-right: 5px;

  &:checked {
    background-color: var(--light-blue);
  }

  &:checked::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--white);
    border-radius: 50%;
    margin: 3px auto;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const TextArea = styled.textarea`
  padding: 0.7rem;
  border: 0;
  border-radius: 5px;
  width: 100%;
  resize: none;

  ::placeholder {
    color: var(--gray)
  }

  &:disabled {
    border: 1px solid var(--lightgray);
  }
`

export const Required = styled.span`
  color: red;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.2rem;
`
export const IconInfo = styled(InfoIcon)`
  color: var(--lightgray);
  font-size: 10px;
`

export const InputFile = styled.input`
  display: none;
`

export const StyledFileButton = styled.label`
  padding: 0.7rem;
  border: 0;
  border-radius: 5px;
  width: 100%;
  background-color: var(--blue);
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.2s;

  &:hover{
    background-color: var(--blue-2);
  }
`

export const MaskInput = styled(InputMask)`
  padding: 0.7rem;
  border: 0;
  border-radius: 5px;
  width: 100%;

  ::placeholder {
    color: var(--gray)
  }

  &:disabled {
    border: 1px solid var(--lightgray);
  }
`

export const Select = styled.select`
  padding: 0.7rem;
  border: 0;
  border-radius: 5px;
  width: 100%;
  max-height: 200px;
`

export const SelectChip = styled(SelectMUI)`
  border: 0;
  border-radius: 5px;
  width: 100%;
  max-height: 200px;
  background-color: var(--white)
`