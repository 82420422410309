import React from 'react'
import * as S from '../../styles/styles-inputs'
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

interface MultiSelectChipProps {
	text: string;
	name: string;
	options: any[];
	handleOnChange: (event: SelectChangeEvent<any>) => void;
	value: any;
	required?: boolean
	disabled?: boolean
	tooltipText?: any
}

export const MultiSelectChip = ({
	text,
	name,
	options,
	handleOnChange,
	value,
	required,
	disabled,
	tooltipText
}: MultiSelectChipProps) => {
	return (
		<S.FormControl>
			<S.Row>
				<S.Label htmlFor={name}>
					{text}
					{required && <S.Required>*</S.Required>}
					:
				</S.Label>
				{tooltipText &&
					<Tooltip title={tooltipText}>
						<S.IconInfo fontSize='small' />
					</Tooltip>
				}
			</S.Row>
			<S.SelectChip
				multiple
				disabled={disabled}
				value={value}
				onChange={handleOnChange}
				renderValue={(selecteds: any) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{selecteds.map((selected: any) => (
							<Chip key={selected} label={options.find(opt => opt._id === selected)?.name} />
						))}
					</Box>
				)}
			>
				{options.map((opt) => (
					<MenuItem key={opt._id} value={opt._id}>{opt.name}</MenuItem>
				))}
			</S.SelectChip>
		</S.FormControl>
	)
}