import React, { useState, useEffect, ChangeEvent } from 'react'
import useFlashMessage from '../../hooks/useFlashMessage'
import api from '../../utils/api'
import * as S from '../../styles/styles-lists'
import IConversation from '../../interfaces/IConversation'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import { SearchInput } from '../../components/SearchInput'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { formatDateBR } from '../../utils/utils';

export const ListConversations = () => {
  const [conversations, setConversations] = useState<IConversation[] | undefined>()
  const [token] = useState(localStorage.getItem('token') || '')
  const { setFlashMessage } = useFlashMessage()

  useEffect(() => {
    api.get('/conversations', {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setConversations(res.data.conversations)
    }).catch((err) => {
      console.log("🚀 ~ err:", err.message)
      setFlashMessage("Houve um problema, tente novamente mais tarde!", "error")
    })
  }, [token, setFlashMessage])

  const removeConversation = async (id: string) => {
    let msgType = "success"

    const data = await api.delete(`/conversations/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      return res.data
    }).catch((err) => {
      msgType = "error"
      return err.response.data
    })

    setFlashMessage(data.message, msgType)
  }

  const handleFilter = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    api.get('/conversations', {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      if (value.trim() === '') {
        setConversations(res.data.conversations)
      } else {
        const filteredStudents = res.data.conversations?.filter((conversation: IConversation) => conversation.subject.toLowerCase().includes(value.toLowerCase()));
        setConversations(filteredStudents);
      }
    })
  }

  return (
    <section>
      <S.ListHeader>
        <S.ListHeaderTitle>
          Contatos&nbsp;({conversations?.length})&nbsp;
          <QuestionAnswerIcon fontSize='small' />
        </S.ListHeaderTitle>

        <SearchInput
          name='search'
          placeholder='Buscar por assunto'
          handleOnChange={handleFilter}
        />

        <S.ListHeaderLink to='/conversation/add'>
          <span>Iniciar Contato</span>
          <AddIcon fontSize='small' />
        </S.ListHeaderLink>
      </S.ListHeader>

      <S.ListContainer>
        {conversations && conversations.length > 0 && conversations.map((conversation) => (
          <S.ListRow key={conversation._id}>
            <S.Data>
              <S.DataInfo>
                <div>
                  <DescriptionIcon fontSize={'small'} />
                  <span>{conversation.subject}</span>
                </div>
              </S.DataInfo>
              <S.DataDate>
                <CalendarTodayIcon fontSize={'small'} />
                <span>
                  {formatDateBR(conversation.createdAt)}
                </span>
              </S.DataDate>
            </S.Data>

            <S.Actions>
              <S.ActionsLink to={`/conversation/chat/${conversation._id}`}>
                <span>Chat</span>
                <QuestionAnswerIcon fontSize={'small'} />
              </S.ActionsLink>
              {conversation.active &&
                <S.ActionsButton color={"red"} onClick={() => removeConversation(conversation._id || "")}>
                  <span>Finalizar</span>
                  <CloseIcon fontSize={'small'} />
                </S.ActionsButton>
              }
            </S.Actions>
          </S.ListRow>
        ))}
        {conversations?.length === 0 && (<p>Não há contatos iniciados!</p>)}
      </S.ListContainer>
    </section>
  )
}