import styled from "styled-components";
import "../index.css"

export const Section = styled.section`
  text-align: center;
  margin-bottom: 1.2rem;
`
export const Header = styled.h1`
  margin-bottom: 0.2rem;
  color: var(--blue);

  strong {
    color: var(--light-blue-2)
  }
`

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 600px;
  margin: 2rem auto;
  background-color: var(--lightgray);
  border-radius: 5px;
  border: 5px solid var(--white);
  padding: 2rem;
`

export const InfosRow = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  strong{
    color:  ${({ color }) => color ? `var(--${color})` : `var(--blue)`};
    padding: 0 5px;
  }
`

export const PaymentRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const PaymentDesc = styled.div<{ color?: string }>`
  span{
    color: black;
  }
  strong{
    color: ${({ color }) => color ?? `var(--${color})`};
    padding: 0 5px;
  }
`

export const PaymentDate = styled.div`
  font-size: 10px;
  color: var(--gray);
`