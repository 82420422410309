import React, { useState, useEffect } from 'react'
import * as S from "../../styles/styles-pages"
import api from '../../utils/api'
import { useParams } from 'react-router-dom'
import ITeacher from '../../interfaces/ITeacher'
import IPayment from '../../interfaces/IPayment'
import { formatCurrency, formatDateBR } from '../../utils/utils'

export const InfoTeacher = () => {
  const [teacher, setTeacher] = useState<ITeacher | undefined>()
  const [infos, setInfos] = useState<any>({})
  const [token] = useState(localStorage.getItem('token') || '')
  const { id } = useParams()

  useEffect(() => {
    api.get(`/teachers/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setTeacher(res.data.teacher)
    })

    api.get(`/teachers/infos/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setInfos(res.data.infos)
    })
  }, [token, id])

  return (
    <S.Section>
      <div>
        <S.Header>Informações sobre o Professor: <strong>{teacher?.name}</strong></S.Header>
        <p>Veja algumas informações úteis!</p>
      </div>

      <S.InfosContainer>
        <strong>- Mês atual</strong>

        <S.InfosRow>
          Aulas realizadas: <strong>{infos.currentMonth || 0}</strong>
        </S.InfosRow>
        <S.InfosRow color='red'>
          Aulas canceladas: <strong>{infos.currentMonthCancelled || 0}</strong>
        </S.InfosRow>

        <br/><strong>- Mês anterior</strong>
        <S.InfosRow>
          Aulas realizadas: <strong>{infos.previousMonth || 0}</strong>
        </S.InfosRow>

        <S.InfosRow color='red'>
          Aulas canceladas: <strong>{infos.previousMonthCancelled || 0}</strong>
        </S.InfosRow>
      </S.InfosContainer>

      {infos.payments && infos.payments.length > 0 &&
        <S.InfosContainer>
          {infos.payments && infos.payments.map((payment: IPayment, index: number) => (
            <S.PaymentRow key={index}>
              <S.PaymentDesc style={{ color: payment.type === "Payment" ? 'red' : 'green' }}>
                <span>{payment.description}</span>
                <strong>{formatCurrency(payment.value)}</strong>
              </S.PaymentDesc>
              <S.PaymentDate>
                {formatDateBR(payment.date)}
              </S.PaymentDate>
            </S.PaymentRow>
          ))}
        </S.InfosContainer>
      }
    </S.Section>
  )
}