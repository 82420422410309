import React, { ReactNode } from 'react'
import * as S from './styles'
import { Context } from '../../context/UserContext'
import { useContext } from 'react'

interface ContainerProps {
  children: ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
  const { authenticated }: { authenticated: boolean; } = useContext(Context)

  return (
    <S.Container authenticated={authenticated.toString()}>
      {children}
    </S.Container>
  )
}
export default Container