import React, { ChangeEvent, FormEvent, useState, useCallback, useEffect, useRef } from 'react'
import * as S from '../../styles/styles-forms'
import { Input } from '../Inputs/text'
import IMessage from '../../interfaces/IMessage'
import SendIcon from '@mui/icons-material/Send';
import IConversation from '../../interfaces/IConversation';
import { formatDateBR } from '../../utils/utils';

interface FormChatProps {
  handleSubmit: (message: any) => void;
  messagesData: IMessage[];
  messageData: IMessage;
  conversationData: IConversation;
  buttonText: string;
}

export const FormChat = ({
  handleSubmit,
  messagesData,
  messageData,
  conversationData,
  buttonText
}: FormChatProps) => {
  const [messages, setMessages] = useState(messagesData || [])
  const [message, setMessage] = useState(messageData || {})
  const [conversation] = useState(conversationData || {})
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMessages(messagesData)
    setMessage(messageData)
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [messagesData, messageData])

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMessage(prev => ({ ...prev, [name]: value }));
  }, [])

  const submit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(message)
  }, [handleSubmit, message])

  return (
    <S.ChatContainer onSubmit={submit}>
      <S.ChatSection>
        {messages.map((message, index) => (
          <S.ChatPill key={index} is_super={message.isSuper ? "true" : "false"}>
            {message.isSuper ? <span><strong>Suporte: </strong>{message.text}</span> : message.text}
            <S.MessageDate>
              {formatDateBR(conversation.createdAt)}&nbsp;
              {new Date(message.createdAt).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
            </S.MessageDate>
          </S.ChatPill>
        ))}
        <div ref={messagesEndRef} /> {/* Referência para o final da lista */}
      </S.ChatSection>
      <div>
        {conversation.active ?
          <div>
            <Input
              text="Messagem"
              type="text"
              name="text"
              placeholder="Digite sua mensagem aqui"
              handleOnChange={handleChange}
              value={message.text || ""}
              required={true}
            />
            <S.SendMessageButton>
              {buttonText}&nbsp;
              <SendIcon />
            </S.SendMessageButton>
          </div>
          :
          <span>Contato finalizado!</span>
        }
      </div>
    </S.ChatContainer>
  )
}
