import React, { ChangeEvent, FormEvent, useState, useCallback } from 'react'
import * as S from '../../styles/styles-forms'
import { Input } from '../Inputs/text'
import { TextArea } from '../TextArea'
import IPlan from '../../interfaces/IPlan'
import SaveIcon from '@mui/icons-material/Save';

interface FormPlanProps {
  handleSubmit: (event: any) => void;
  planData: IPlan;
  buttonText: string
}

export const FormPlan = ({
  handleSubmit,
  planData,
  buttonText
}: FormPlanProps) => {
  const [plan, setPlan] = useState(planData || {})

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPlan(prevPlan => ({ ...prevPlan, [name]: value }));
  }, [])

  const handleTextArea = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setPlan(prevPlan => ({ ...prevPlan, [name]: value }));
  }, [])

  const submit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(plan)
  }, [handleSubmit, plan])

  return (
    <S.FormContainer onSubmit={submit}>
      <Input
        text="Nome do Plano"
        type="text"
        name="name"
        placeholder="Digite o nome"
        handleOnChange={handleChange}
        value={plan.name || ""}
        required={true}
      />
      <Input
        text="Valor Mensal"
        type="value"
        name="value"
        placeholder="Digite o valor"
        handleOnChange={handleChange}
        value={plan.value || ""}
        required={true}
      />
      <Input
        text="Quantidade de Aulas Mensais"
        type="quantity"
        name="quantity"
        placeholder="Digite a quantidade"
        handleOnChange={handleChange}
        value={plan.quantity || ""}
        required={true}
      />
      <TextArea
        text="Observação"
        name="observation"
        handleOnChange={handleTextArea}
        value={plan.observation || ""}
        placeholder={"Observação"}
      />
      <S.SubmitButton>
        {buttonText}&nbsp;
        <SaveIcon/>
      </S.SubmitButton>
    </S.FormContainer>
  )
}