import React from 'react'
import Logo from '../../assets/logo-with-no-bg.png'
import * as S from './styles'
import { Context } from '../../context/UserContext'
import { useContext } from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import PortraitIcon from '@mui/icons-material/Portrait';
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import ApartmentIcon from '@mui/icons-material/Apartment';
// import SettingsIcon from '@mui/icons-material/Settings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export const SideMenu = () => {
  const { authenticated, logout, isSuper }: {
    authenticated: boolean;
    logout: () => void,
    isSuper: boolean
  } = useContext(Context)

  return (
    <>
      {authenticated &&
        <S.Navbar>
          <S.NavbarLogo to={"/"}>
            <S.ImgLogo src={Logo} alt="Wayvee" />
          </S.NavbarLogo>
          <S.List>
            <S.Section>
              <div>
                {isSuper && <>
                  <S.ListItemSuper to="/companies">
                    <BusinessIcon fontSize='small' />
                    <span>Empresas</span>
                  </S.ListItemSuper>
                  <S.ListItemSuper to="/users">
                    <PersonIcon fontSize='small' />
                    <span>Usuários</span>
                  </S.ListItemSuper>
                </>}
                <S.ListItem to="/payments">
                  <AttachMoneyIcon fontSize='small' />
                  <span>Contas</span>
                </S.ListItem>
                <S.ListItem to="/students">
                  <GroupsIcon fontSize='small' />
                  <span>Alunos</span>
                </S.ListItem>
                <S.ListItem to="/plans">
                  <AnalyticsIcon fontSize='small' />
                  <span>Planos</span>
                </S.ListItem>
                <S.ListItem to="/teachers">
                  <SchoolIcon fontSize='small' />
                  <span>Professores</span>
                </S.ListItem>
                <S.ListItem to="/lessons">
                  <CastForEducationIcon fontSize='small' />
                  <span>Aulas</span>
                </S.ListItem>
                <S.ListItem to="/user/profile" color={"light-blue-2"}>
                  <PortraitIcon fontSize='small' />
                  <span>Perfil</span>
                </S.ListItem>
                <S.ListItem to="/company/profile" color={"light-blue-2"}>
                  <ApartmentIcon fontSize='small' />
                  <span>Empresa</span>
                </S.ListItem>
                {/* <S.ListItem to="/company/settings" color={"light-blue-2"}>
                  <SettingsIcon fontSize='small'/>
                  <span>Configurações</span>
                </S.ListItem> */}
              </div>
              <div>
                <S.ListItem to="/conversations">
                  <QuestionAnswerIcon fontSize='small' />
                  <span>Contato</span>
                </S.ListItem>
                <S.ListItem to="/login" onClick={logout}>
                  <LogoutIcon fontSize='small' />
                  <span>Sair</span>
                </S.ListItem>
              </div>
            </S.Section>
          </S.List>
        </S.Navbar>
      }
    </>
  )
}