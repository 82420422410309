import styled from "styled-components";
import "../../index.css"

export const FormContainer = styled.div`
  max-width: 600px;
  margin: 2rem auto;
  background-color: var(--lightgray);
  border-radius: 5px;
  border: 4px solid var(--white);
  padding: 1rem;
`