import React, { useState, useEffect } from 'react'
import * as S from "../../styles/styles-pages"
import api from '../../utils/api'
import { useParams } from 'react-router-dom'
import useFlashMessage from '../../hooks/useFlashMessage'
import { FormChat } from '../../components/Forms/chat'
import IMessage from '../../interfaces/IMessage'
import IConversation from '../../interfaces/IConversation'

export const ConversationChat = () => {
  const [conversation, setConversation] = useState<IConversation | undefined>()
  const [messages, setMessages] = useState<IMessage[]>([])
  const [token] = useState(localStorage.getItem('token') || '')
  const { id } = useParams()
  const { setFlashMessage } = useFlashMessage()
  const voidMessage: IMessage = {
    _id: '',
    text: '',
    conversation: '',
    createdAt: ''
  }

  useEffect(() => {
    api.get(`/conversations/messages/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setMessages(res.data.messages)
    })

    api.get(`/conversations/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setConversation(res.data.conversation)
    })
  }, [token, id])

  const updateConversation = async (message: any) => {
    let msgType = "success"

    const formData = new FormData()

    await Object.keys(message).forEach((key) => {
      formData.append(key, message[key])
    })

    formData.set('conversation', conversation?._id || "")

    const data = await api.post(`/conversations/message`, formData, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then((res: any) => {
      return res.data
    }).catch((err) => {
      msgType = 'error'
      return err.response.data
    })

    setMessages([...messages, data.newMessage])
    setFlashMessage(data.message, msgType)

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <S.Section>
      <div>
        <S.Header>Chat: <strong>{conversation?.subject}</strong></S.Header>
        <p>Aguarde até que possamos responder.</p>
      </div>
      {conversation?._id && messages.length &&
        <FormChat
          buttonText="Enviar"
          handleSubmit={updateConversation}
          messageData={voidMessage}
          messagesData={messages}
          conversationData={conversation}
        />
      }
    </S.Section>
  )
}