import React, { useState, useEffect } from 'react'
import * as S from "../../styles/styles-pages"
import api from '../../utils/api'
import { useParams } from 'react-router-dom'
import IStudent from '../../interfaces/IStudent'
import IPayment from '../../interfaces/IPayment'
import { formatCurrency, formatDateBR } from '../../utils/utils'

export const InfoStudent = () => {
  const [student, setStudent] = useState<IStudent | undefined>()
  const [infos, setInfos] = useState<any>({})
  const [token] = useState(localStorage.getItem('token') || '')
  const { id } = useParams()

  useEffect(() => {
    api.get(`/students/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setStudent(res.data.student)
    })

    api.get(`/students/infos/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      setInfos(res.data.infos)
    })
  }, [token, id])

  return (
    <S.Section>
      <div>
        <S.Header>Informações sobre o Aluno: <strong>{student?.name}</strong></S.Header>
        <p>Veja algumas informações úteis!</p>
      </div>

      <S.InfosContainer>
        {/* <S.InfosRow>Aulas não agendadas restantes esse mês: <strong>{infos.currentMonth || 0}</strong></S.InfosRow> */}
        <strong>- Mês atual</strong>

        <S.InfosRow>
          Aulas agendadas:
          <strong>{infos?.currentMonth?.find((info: any) => info.status === "SCHEDULED")?.total || 0}</strong>
        </S.InfosRow>

        <S.InfosRow>
          Aulas realizadas:
          <strong>{infos?.currentMonth?.find((info: any) => info.status === "DONE")?.total || 0}</strong>
        </S.InfosRow>

        <S.InfosRow color='red'>
          Faltas registradas:
          <strong>{infos?.currentMonth?.find((info: any) => info.status === "MISSED")?.total || 0}</strong>
        </S.InfosRow>

        <S.InfosRow color='red'>Aulas canceladas: <strong>{infos?.currentMonthCancelled || 0}</strong></S.InfosRow>

        <br/><strong>- Mês anterior</strong>
        <S.InfosRow>
          Aulas agendadas:
          <strong>{infos?.previousMonth?.find((info: any) => info.status === "SCHEDULED")?.total || 0}</strong>
        </S.InfosRow>

        <S.InfosRow>
          Aulas realizadas:
          <strong>{infos?.previousMonth?.find((info: any) => info.status === "DONE")?.total || 0}</strong>
        </S.InfosRow>

        <S.InfosRow color='red'>
          Faltas registradas:
          <strong>{infos?.previousMonth?.find((info: any) => info.status === "MISSED")?.total || 0}</strong>
        </S.InfosRow>

        <S.InfosRow color='red'>Aulas canceladas: <strong>{infos?.previousMonthCancelled || 0}</strong></S.InfosRow>
      </S.InfosContainer>

      {infos.payments && infos.payments.length > 0 &&
        <S.InfosContainer>
          {infos.payments.map((payment: IPayment, index: number) => (
            <S.PaymentRow key={index}>
              <S.PaymentDesc style={{ color: payment.type === "Payment" ? 'red' : 'green' }}>
                <span>{payment.description}</span>
                <strong>{formatCurrency(payment.value)}</strong>
              </S.PaymentDesc>
              <S.PaymentDate>
                {formatDateBR(payment.date)}
              </S.PaymentDate>
            </S.PaymentRow>
          ))}
        </S.InfosContainer>
      }
    </S.Section>
  )
}