import React, { useState, useEffect, ChangeEvent, useCallback } from 'react'
import * as S from '../../styles/styles-profiles'
import api from '../../utils/api'
import useFlashMessage from '../../hooks/useFlashMessage'
import ISetting from '../../interfaces/ISetting'
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import { Input } from '../../components/Inputs/text'
import { InputCheckbox } from '../../components/Inputs/checkbox'

export const SettingsCompany = () => {
  const [setting, setSetting] = useState<ISetting>({
    _id: '',
    blockByPayment: false,
    toleratePaymentDays: 0,
    maxLessonsPerDayTeacher: 0,
    maxLessonsPerDayStudent: 0,
    maxLessonsPerMonthTeacher: 0,
    maxLessonsPerMonthStudent: 0,
  })
  const [token] = useState(localStorage.getItem('token') || '')
  const { setFlashMessage } = useFlashMessage()
  const companyId = localStorage.getItem('company')?.replace(/"/g, '')

  useEffect(() => {
    api.get(`/settings/${companyId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    }).then((res) => {
      const data = res.data.setting;
      if (!data) return;

      setSetting(data);
    })
  }, [companyId, token])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSetting(prevCompany => ({ ...prevCompany, [name]: value }));
  }, [])

  const handleChangeCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setSetting(prevCompany => ({ ...prevCompany, [name]: checked }));
  }, [])

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    let msgType = "success"

    const formData = new FormData()

    if (setting)
      Object.entries(setting).forEach(([key, value]) => {
        formData.append(key, value);
      });

    const data = await api.patch(`/settings/${setting?._id}`, formData, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data
    }).catch((err) => {
      msgType = 'error'
      return err.response.data
    })

    setFlashMessage(data.message, msgType)

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <S.Section>
      <S.Header>
        <S.HeaderTitle>
          Configurações&nbsp;&nbsp;
          <SettingsIcon />
        </S.HeaderTitle>
        <p>Configurações gerais da empresa!</p>
      </S.Header>
      <S.FormContainer>

        <InputCheckbox
          text="Bloquear por falta de pagamento"
          name="blockByPayment"
          value={setting.blockByPayment}
          handleOnChange={handleChangeCheckbox}
          tooltipText="Não permitirá agendar aulas para alunos inadimplentes!"
        />

        <Input
          text="Dias de Tolerância"
          type="number"
          name="toleratePaymentDays"
          placeholder="Digite a quantidade de dias"
          handleOnChange={handleChange}
          value={setting.toleratePaymentDays || ""}
          tooltipText="Número de dias que serão tolerados após o vencimento da mensalidade antes de bloquear o aluno de ser incluído nas aulas. 0 = Sem limite!"
          disabled={!setting.blockByPayment}
        />

        <Input
          text="Máximo de aulas por dia (Aluno)"
          type="number"
          name="maxLessonsPerDayStudent"
          placeholder="Digite a quantidade de aulas"
          handleOnChange={handleChange}
          value={setting.maxLessonsPerDayStudent || ""}
          tooltipText="Defina aqui o maximo de aulas diárias que um aluno pode fazer. 0 = Sem limite!"
        />

        <Input
          text="Máximo de aulas por dia (Professor)"
          type="number"
          name="maxLessonsPerDayTeacher"
          placeholder="Digite a quantidade de aulas"
          handleOnChange={handleChange}
          value={setting.maxLessonsPerDayTeacher || ""}
          tooltipText="Defina aqui o maximo de aulas diárias que um professor pode dar. 0 = Sem limite!"
        />

        <Input
          text="Máximo de aulas por mês (Aluno)"
          type="number"
          name="maxLessonsPerMonthStudent"
          placeholder="Digite a quantidade de aulas"
          handleOnChange={handleChange}
          value={setting.maxLessonsPerMonthStudent || ""}
          tooltipText="Defina aqui o maximo de aulas mensais que um aluno pode fazer. 0 = Sem limite!"
        />

        <Input
          text="Máximo de aulas por mês (Professor)"
          type="number"
          name="maxLessonsPerMonthTeacher"
          placeholder="Digite a quantidade de aulas"
          handleOnChange={handleChange}
          value={setting.maxLessonsPerMonthTeacher || ""}
          tooltipText="Defina aqui o maximo de aulas mensais que um professor pode dar. 0 = Sem limite!"
        />

        <S.SubmitButton onClick={handleSubmit}>
          Salvar&nbsp;
          <SaveIcon />
        </S.SubmitButton>
      </S.FormContainer>
    </S.Section>
  )
}